<template>
  <div v-if="!communicationHidden">
    <div v-if="!isLink"
         class="communication-in-app-alert alert alert-dismissible"
         role="alert">
      <img src="../../images/robot-love-face.svg">
      <span v-html="$t('components.core.communication-in-app.hello', { userName: userName })" />
      <a :id="communicationId"
         href="javascript:void(0)"
         @click="startGuide">{{ linkText }}</a>
    </div>

    <div v-else>
      <a :id="communicationId"
         class="communication-in-app-link"
         href="javascript:void(0)"
         @click="startGuide">{{ linkText }}</a>
    </div>
  </div>
</template>

<script>
import I18n from "core/i18n";
import { Communication } from "core/models/communication";

export default {
  props: {
    communicationId: {
      type: String,
      required: true
    },
    userName: {
      default: null,
      type: String
    },
    steps: {
      type: Array,
      required: true
    },
    showBullets: {
      default: true,
      type: Boolean
    },
    showProgressBar: {
      default: false,
      type: Boolean
    },
    isLink: {
      default: false,
      type: Boolean
    },
    linkText: {
      default: I18n.t("components.core.communication-in-app.click-text"),
      type: String
    },
    hideWhenCompleted: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      communication: Communication.get(this.communicationId)
    };
  },

  methods: {
    startGuide: function (event) {
      const self = this;

      // using a legacy function to track click event to GA
      if (window.gaTrackClickEvent) {
        gaTrackClickEvent(self.communicationId, self.userName + " communication start");
      }

      // track communication start event
      if (window.analytics) {
        window.analytics.track("Communication start", {
          id: self.communicationId
        });
      }

      const introJS = this.$intro();
      introJS.setOptions({
        nextLabel: I18n.t("text.next"),
        prevLabel: I18n.t("text.previous"),
        doneLabel: I18n.t("text.close"),
        skipLabel: "<i class=\"fas fa-times fa-fw\"></i>",
        showStepNumbers: false,
        exitOnEsc: true,
        exitOnOverlayClick: true,
        showBullets: this.showBullets,
        showProgress: this.showProgressBar,
        keyboardNavigation: false,
        steps: this.introjsSteps
      }).start();

      introJS.oncomplete(function () {
        self.communication.completed = true;
        self.communication.save();

        // using a legacy function to track click event to GA
        if (window.gaTrackClickEvent) {
          gaTrackClickEvent(self.communicationId, self.userName + " communication done");
        }

        // track communication done event
        if (window.analytics) {
          window.analytics.track("Communication done", {
            id: self.communicationId
          });
        }
      });
    }
  },

  computed: {
    introjsSteps: function () {
      const introjsSteps = [];

      this.steps.forEach(function (step) {
        introjsSteps.push({
          intro: step.text,
          element: step.element
        });
      });

      return introjsSteps;
    },

    communicationHidden () {
      const self = this;
      return (self.hideWhenCompleted && self.communication.completed);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.communication-in-app-alert {
  @include box-shadow();

  background-color: $color-blue-70;
  color: $color-white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  span {
    margin-right: $spacing-sm;
  }

  a {
    color: $color-white;
    font-weight: bold;

    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: $color-white;
      text-decoration: none;
    }
  }
}

.communication-in-app-link {
  color: $color-blue-40;

  &:hover,
  &:focus {
    color: $color-blue-60;
  }
}

@media (max-width: 768px) {
  .communication-in-app-alert {
    display: inherit;
  }
}
</style>

<style lang="scss">
@import "~styles/framework/variables";
@import "~styles/framework/mixins";

.introjs-overlay {
  @include fade-effect();

  background-color: rgb(0, 0, 0) !important;
  opacity: 0.7 !important;
}

//style to hint
.introjs-hint {
  z-index: 1030;

  .introjs-hint-dot,
  .introjs-hint-pulse,
  &:hover > .introjs-hint-pulse {
    border-color: rgba(250, 82, 82, 0.6) !important;
  }
}

.introjs-hintReference {
  .introjs-button {
    background-color: #3A87AD !important;
    margin: 0;
  }
}

.introjs-fixedTooltip {
  position: absolute;
}

.introjs-helperLayer {
  border: 0;
}

a.introjs-disabled {
  display: none;
  opacity: 0;
  visibility: hidden;
}

//style to tooltip
.introjs-tooltip {
  @include fade-effect();

  padding: 0;
  overflow: hidden;
  min-width: 600px;
  font-family: $product-font-sans-serif;

  img {
    margin-bottom: 10px;
  }
}

//tooltip progressbar
.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 5px;
  margin-top: 10px;
  border-radius: 0;
  background-color: #ECF0F1;
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
}

//tootip bullets
.introjs-bullets {
  padding-bottom: $spacing-lg;

  ul {
    margin-top: 0;
  }
}

//tooltip text
.introjs-tooltiptext {
  padding: $spacing-xxl $spacing-lg $spacing-lg;
  font-size: $font-size-sm;
  color: $color-gray-100;

  p,
  h4 {
    line-height: 1.75em;
  }
}

//tooltip buttons
.introjs-tooltipbuttons {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  min-height: 62px;

  //tooltip all buttons
  .introjs-button {
    display: inline;
    margin: 0;
    padding: 4px 0;
    font-size: $font-size-sm;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
    border: 0;
    background-color: transparent;
    color: $color-blue-40;
    text-decoration: none;

    &:hover,
    &:focus {
      background-image: none;
      background-color: transparent;
      color: $color-blue-60;
      text-decoration: none;
      box-shadow: none;
    }
  }

  //tooltip close button
  .introjs-skipbutton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    opacity: 0.5;
    color: $color-gray-100;
    font-size: $font-size-sm;

    //tooltip done button
    &.introjs-donebutton {
      display: inherit !important;
      opacity: 1 !important;
      color: $color-blue-40;
      float: right;
      position: inherit;
    }
  }

  //tooltip previous button
  .introjs-prevbutton {
    float: left;
  }

  //tooltip next button
  // .introjs-nextbutton {}
}

.introjs-top-left-aligned {
  left: 0 !important;
}

.communication-rating {
  i {
    color: $color-gray-10;
    margin-left: $spacing-xs;
    margin-right: $spacing-xs;
    font-size: 24px;

    &:hover {
      color: $color-gray-30;
      cursor: pointer;
    }

    &.communication-like-icon {
      &.active {
        color: $color-red-60;
      }
    }

    &.communication-dislike-icon {
      &.active {
        color: $color-gray-100;
      }
    }
  }
}
</style>
